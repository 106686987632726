<template>
  <div style="position: relative" v-if="internalNode">
    <!-- unsaved changes -->
    <generic-dialog v-model="showUnsavedChangesDialog" width="300">
      <div>
        Tienes cambios sin guardar. ¿Deseas cerrar sin guardar los cambios?
      </div>
      <div class="d-flex mt-4">
        <v-btn @click="$emit('setNode', null)" class="me-auto"
          >Sí, cerrar</v-btn
        >
        <v-btn @click="showUnsavedChangesDialog = false" class="ms-auto"
          >No, volver</v-btn
        >
      </div>
    </generic-dialog>
    <!-- delete dialog -->
    <generic-dialog v-model="deleteDialog" width="300">
      <div>¿Estás seguro de eliminar este nodo?</div>
      <div class="d-flex mt-4">
        <v-btn @click="removeNode()" class="me-auto">Sí, eliminar</v-btn>
        <v-btn @click="deleteDialog = false" class="ms-auto">No</v-btn>
      </div>
    </generic-dialog>
    <!-- comment creator -->
    <comment-creator
      v-if="internalNode"
      v-model="addComment"
      :node="internalNode.id"
    />
    <!-- answer -->
    <answer
      v-model="editAnswer"
      :answer="answer"
      v-if="answer"
      :isListPicker="node.type == 'list-picker'"
    />
    <!-- node weight -->
    <node-weight
      v-if="nodeWeight"
      v-model="editNodeWeight"
      :node-weight="nodeWeight"
      @selectNodeWeight="selectNodeWeight"
    />
    <!-- node weight -->
    <answer-validation
      v-if="answerValidation"
      v-model="editAnswerValidation"
      :answer-validation="answerValidation"
      @selectAnswerValidation="selectAnswerValidation"
    />
    <!-- node variable -->
    <node-variable
      v-if="nodeVariable"
      v-model="editNodeVariable"
      :node-variable="nodeVariable"
      @selectNodeVariable="selectNodeVariable"
    />
    <!-- node resource -->
    <node-resource
      v-if="nodeResource"
      v-model="editNodeResource"
      :node-resource="nodeResource"
      @selectNodeResource="selectNodeResource"
    ></node-resource>
    <!-- node -->
    <generic-dialog
      height="600"
      width="600"
      :persistent="hasChanges"
      @persist="showUnsavedChangesDialog = true"
      card-style="z-index: 20001 !important; height: 600px;"
      v-model="showNode"
      title-class="h2"
      title="Edición de nodo"
      @save="sendSaveNode"
      :save-condition="role == 'admin' || role == 'writer'"
      @delete="deleteDialog = true"
      :delete-condition="role == 'admin'"
      @input="$emit('setNode', null)"
    >
      <template slot="buttons">
        <v-tooltip top v-if="role == 'admin' || role == 'writer'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="addComment = true" icon v-bind="attrs" v-on="on">
              <v-icon color="blue"> mdi-forum-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>Agregar comentario</span>
        </v-tooltip>
      </template>
      <!-- node elements -->
      <div
        name="node-elements"
        style="max-height: 480px !important; overflow-y: auto"
      >
        <v-card
          :color="getApprovalColor"
          v-if="role == 'admin' && needsApproval"
          class="text-left pa-4 ma-2"
        >
          <div class="text-bold">
            <v-icon class="me-2">
              {{
                {
                  approved: 'mdi-check-decagram-outline',
                  pending: 'mdi-clock-outline',
                  received: 'mdi-clock-outline',
                  rejected: 'mdi-alert-circle-outline',
                  '': 'mdi-alert-circle-outline',
                }[internalNode.status]
              }}
            </v-icon>
            <span>{{
              {
                '': 'Este nodo necesita ser aprobado especialmente.',
                approved: 'Este nodo ha sido aprobado.',
                pending: 'Este nodo tiene aprobación pendiente.',
                received: 'Este nodo tiene aprobación pendiente.',

                rejected:
                  'El nodo no fue aprobado. Necesita ser aprobado nuevamente.',
              }[internalNode.status]
            }}</span>
          </div>

          <div v-if="internalNode.start && internalNode.type == 'list-picker'">
            <b
              >El nodo no puede ser de tipo "list-picker" al ser nodo inicial.
              Sólo se permite nodos tipo quick-reply ó text.</b
            >
          </div>
          <v-btn
            class="mt-1"
            v-if="
              internalNode.status == '' || internalNode.status == 'rejected'
            "
            :disabled="internalNode.start && internalNode.type == 'list-picker'"
            @click="approve"
            >Solicitar aprobación</v-btn
          >
        </v-card>
        <v-form ref="form">
          <v-text-field
            class="mt-2"
            outlined
            dense
            :hide-details="
              validateName(internalNode.name) && shortName(internalNode.name)
                ? true
                : false
            "
            label="name"
            :style="role != 'admin' ? 'pointer-events: none !important' : ''"
            :readonly="role != 'admin'"
            :rules="[
              (v) =>
                validateName(v) ||
                'El nombre solo puede contener letras y guión bajo.',
              (v) =>
                shortName(v) ||
                'El nombre no puede ser de largo mayor a 50 caracteres.',
            ]"
            v-model="internalNode.name"
            :counter="50"
          ></v-text-field>
        </v-form>

        <v-checkbox
          dense
          hide-details
          label="nodo inicial"
          :readonly="role != 'admin'"
          :style="role != 'admin' ? 'pointer-events: none !important' : ''"
          v-model="internalNode.start"
        ></v-checkbox>
        <div class="d-flex">
          <v-checkbox
            dense
            hide-details
            :readonly="role != 'admin'"
            :style="role != 'admin' ? 'pointer-events: none !important' : ''"
            label="Requiere post-procesamiento"
            v-model="internalNode.special"
          ></v-checkbox>
          <tooltip class="mt-2" style="transform: scale(0.8)">
            Un nodo <b>requiere post-procesamiento</b> si es necesario hacer
            alguna lógica especial con la <b>respuesta</b> del usuario a este
            nodo. Por ejemplo, si con la respuesta en formato de texto del
            usuario hay que hacer una búsqueda a una base de datos.
          </tooltip>
        </div>
        <div class="d-flex">
          <v-checkbox
            dense
            hide-details
            :readonly="role != 'admin'"
            :style="role != 'admin' ? 'pointer-events: none !important' : ''"
            label="Requiere pre-procesamiento"
            v-model="internalNode.preprocess"
          ></v-checkbox>
          <tooltip class="mt-2" style="transform: scale(0.8)">
            Un nodo requiere <b>pre-procesamiento</b> si es necesario hacer
            alguna lógica especial con este nodo antes de ser mostrado al
            usuario. Por ejemplo, si
            <b>la cantidad de respuestas a mostrar</b> o bien
            <b>el texto a mostrar</b> depende de alguna llamada a algún API
            externa, o a algún factor establecido en el flujo.
          </tooltip>
        </div>
        <div class="d-flex">
          <v-checkbox
            dense
            hide-details
            :readonly="role != 'admin'"
            :style="role != 'admin' ? 'pointer-events: none !important' : ''"
            label="Nodo sumidero"
            v-model="internalNode.sink"
          ></v-checkbox>
          <tooltip class="mt-2" style="transform: scale(0.8)">
            Un nodo es <b>sumidero</b> si es un nodo al que el usuario irá en
            caso de hablar directamente con el chatbot, sin ser parte de ninguna
            intervención. De no existir un nodo tipo sumidero, el nodo
            <b>start</b> será el nodo sumidero por defecto.
          </tooltip>
        </div>
        <v-checkbox
          dense
          hide-details
          :readonly="role != 'admin'"
          :style="role != 'admin' ? 'pointer-events: none !important' : ''"
          label="Siguiente automático"
          v-model="internalNode.auto_next"
        ></v-checkbox>

        <div class="d-flex">
          <v-checkbox
            dense
            hide-details
            :readonly="role != 'admin'"
            :style="role != 'admin' ? 'pointer-events: none !important' : ''"
            label="Inteligencia artificial"
            v-model="internalNode.ai"
          ></v-checkbox>
          <tooltip class="mt-2" style="transform: scale(0.8)">
            Un nodo es de tipo <b>inteligencia artificial</b> si es un nodo que
            consultará información presente en <b>recursos</b>, ya sea archivos
            PDF o tablas.
          </tooltip>
        </div>
        <div class="d-flex">
          <v-checkbox
            dense
            hide-details
            class="mb-3"
            :readonly="role != 'admin'"
            :style="role != 'admin' ? 'pointer-events: none !important' : ''"
            label="Consulta de tabla"
            v-model="internalNode.api"
          ></v-checkbox>
          <tooltip class="mt-2" style="transform: scale(0.8)">
            Un nodo es de categoría <b>consulta de tabla</b> si es que es un
            nodo que consultará una tabla presente en los recursos directamente,
            es decir, filtrar o buscar opciones a través de dicha tabla.
          </tooltip>
        </div>
        <!--<v-text-field
          outlined
          v-if="internalNode.ai && role == 'admin'"
          dense
          hide-details
          class="mb-3"
          label="AI index"
          v-model="internalNode.ai_index"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          class="mb-3"
          v-if="internalNode.ai && role == 'admin'"
          hide-details
          label="AI description"
          v-model="internalNode.ai_description"
        ></v-text-field>-->
        <!-- <div class="w-100 d-flex" v-if="internalNode.start">
            <div class="me-auto">status: {{ status }}</div>
          </div> -->

        <v-textarea
          class="pt-2"
          outlined
          dense
          hide-details
          label="text"
          :readonly="role == 'reader'"
          :style="role == 'reader' ? 'pointer-events: none !important' : ''"
          v-model="internalNode.text"
        ></v-textarea>
        <v-select
          class="pt-2"
          outlined
          dense
          hide-details
          :readonly="role != 'admin'"
          :style="role != 'admin' ? 'pointer-events: none !important' : ''"
          label="type"
          :items="[
            'quick-reply',
            'list-picker',
            'text',
            'media',
            'link',
            'card',
          ]"
          v-model="internalNode.type"
        >
          <template v-slot:item="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" style="width: 100%">
                  {{ item }}
                </div>
              </template>
              {{ getWarning(item) }}
            </v-tooltip>
          </template>
        </v-select>
        <v-text-field
          class="pt-2"
          outlined
          dense
          hide-details
          v-if="internalNode.type == 'list-picker'"
          label="nombre de botón de opciones"
          :style="role != 'admin' ? 'pointer-events: none !important' : ''"
          :readonly="role != 'admin'"
          v-model="internalNode.options_title"
        ></v-text-field>
        <v-text-field
          class="pt-2"
          outlined
          dense
          hide-details
          v-if="internalNode.type == 'media' || internalNode.type == 'card'"
          label="url al video o imagen"
          :style="role != 'admin' ? 'pointer-events: none !important' : ''"
          :readonly="role != 'admin'"
          v-model="internalNode.media_url"
        ></v-text-field>

        <v-text-field
          outlined
          dense
          hide-details
          v-if="internalNode.type == 'card' || internalNode.type == 'link'"
          class="pt-2"
          label="link clickeable"
          :style="role != 'admin' ? 'pointer-events: none !important' : ''"
          :readonly="role != 'admin'"
          v-model="internalNode.link"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          hide-details
          v-if="internalNode.type == 'card' || internalNode.type == 'link'"
          class="pt-2"
          label="texto de botón al link"
          :style="role != 'admin' ? 'pointer-events: none !important' : ''"
          :readonly="role != 'admin'"
          v-model="internalNode.button"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          hide-details
          class="pt-2"
          label="content SID (optional)"
          :style="role != 'admin' ? 'pointer-events: none !important' : ''"
          :readonly="role != 'admin'"
          v-model="internalNode.content_sid"
        ></v-text-field>
        <!-- variables -->
        <v-card
          v-if="internalNode.content_sid.length > 10"
          class="ps-4 pe-4 pt-2 pb-2 mt-2"
          style="background: lightyellow"
        >
          <div class="d-flex">
            <h3 class="text-left">Equivalencia de variables en este nodo:</h3>
            <tooltip>
              Cuando se define un <b>template para el mensaje</b>, este puede
              tener variables. Por restricciones de WhatsApp,
              <b>estas variables solo pueden ser numéricas</b>. Por ende,
              <b
                >aquí se define la equivalencia entre variables del usuario a
                variables del template</b
              >. Por ejemplo, si queremos rellenar la variable '1' en el
              template con la información guardada en la variable 'name' del
              usuario, se debería agregar la equivalencia 'name' - '1'.
            </tooltip>
          </div>
          <div
            v-for="(nodeVariable, index) in internalNode.variables"
            :key="index"
          >
            <v-card class="d-flex pa-2 mt-2">
              <div class="mt-auto mb-auto">
                {{ nodeVariable.variable }}: {{ nodeVariable.number }}
              </div>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="selectNodeVariable(nodeVariable)"
                    v-if="role == 'admin'"
                    icon
                    class="ms-auto"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </v-card>
          </div>
          <v-btn
            class="mt-2"
            @click="addNodeVariable({ node: internalNode.id })"
            v-if="role == 'admin'"
            >Agregar equivalencia<v-icon class="ms-2">mdi-plus</v-icon></v-btn
          >
        </v-card>
        <!-- answers -->
        <v-card class="ps-4 pe-4 pt-2 pb-2 mt-2" style="background: lightgreen">
          <div class="d-flex">
            <h3 class="text-left">Respuestas posibles a este nodo:</h3>
          </div>

          <div v-for="(answer, index) in sortedAnswers" :key="index">
            <v-card class="d-flex pa-1 mt-1">
              <div class="mt-auto mb-auto">
                <span class="text-label color--neutral-50-t me-1"
                  >{{ answer.number }}.</span
                >
                {{ answer.text }}
              </div>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="selectAnswer(answer)"
                    v-if="role == 'admin' || role == 'writer'"
                    icon
                    class="ms-auto"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </v-card>
          </div>
          <v-tooltip top :disabled="!checkDisabledAddAnswer()">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn
                  class="mt-2"
                  :disabled="checkDisabledAddAnswer()"
                  @click="addAnswer({ node: internalNode.id })"
                  v-if="role == 'admin'"
                  >Agregar respuesta
                  <v-icon class="ms-2">mdi-plus</v-icon></v-btn
                >
              </div>
            </template>
            <span>{{ checkDisabledAddAnswer() }}</span>
          </v-tooltip>
        </v-card>

        <!-- weights -->
        <v-card class="ps-4 pe-4 pt-2 pb-2 mt-2" style="background: lightblue">
          <div class="d-flex pt-2 pb-2">
            <h3 class="text-left">Ponderadores de salida de este nodo:</h3>
            <tooltip>
              Para definir el siguiente nodo al que el usuario irá, se
              multiplicarán estos ponderadores por el valor asignados a las
              características correspondientes de este usuario. Luego, se verá
              qué nodo siguiente tiene la menor distancia vectorial.
            </tooltip>
          </div>

          <div v-for="(weight, index) in internalNode.weights" :key="index">
            <v-card class="d-flex pa-1 mt-1">
              <div class="mt-auto mb-auto">
                {{ weight.feature }}: {{ weight.weight }}
              </div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="selectNodeWeight(weight)"
                    v-if="role == 'admin'"
                    icon
                    class="ms-auto"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </v-card>
          </div>
          <v-btn class="mt-2" @click="sendAddNodeWeight" v-if="role == 'admin'"
            >Agregar ponderador <v-icon class="ms-2">mdi-plus</v-icon></v-btn
          >
        </v-card>
        <!-- validations -->
        <!--<v-card class="ps-4 pe-4 pt-2 pb-2 mt-2" style="background: lightblue">
          <div class="d-flex pt-2 pb-2">
            <h3 class="text-left">Validaciones de este nodo:</h3>
            <tooltip>
              A continuación se definen las validaciones correspondientes a este
              nodo, que aplicarán a la respuesta del usuario. Por ejemplo,
              podemos validar si es que una respuesta es numérica o no.
            </tooltip>
          </div>

          <div
            v-for="(validation, index) in internalNode.validations"
            :key="index"
          >
            <v-card class="d-flex pa-1 mt-1">
              <div class="mt-auto mb-auto">
                {{ validation.category }} {{ validation.value }}
                {{ validation.error_text }} {{ validation.number }}
              </div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="selectAnswerValidation(validation)"
                    v-if="role == 'admin'"
                    icon
                    class="ms-auto"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </v-card>
          </div>
          <v-btn
            class="mt-2"
            @click="sendAddAnswerValidation"
            v-if="role == 'admin'"
            >Agregar validación <v-icon class="ms-2">mdi-plus</v-icon></v-btn
          >
        </v-card>-->
        <!-- AI -->
        <v-card
          class="ps-4 pe-4 pt-2 pb-2 mt-2"
          style="background: lightpink"
          v-if="internalNode.ai"
        >
          <div class="d-flex pt-2 pb-2">
            <h3 class="text-left">Recursos asociados a este nodo:</h3>
            <tooltip>
              Esta sección permite asociar recursos cargados a este nodo, de tal
              manera que el nodo responderá con información presente en los
              recursos.
            </tooltip>
          </div>

          <div
            v-for="(resource, index) in node.resources"
            :key="index"
            class="d-flex"
          >
            <v-card class="d-flex pa-2 mt-2" style="width: 100%">
              <div class="mt-auto mb-auto">
                {{
                  resource.resource
                    ? getResourceName(resource.resource)
                    : 'No se ha definido recurso'
                }}
              </div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="selectNodeResource(resource)"
                    v-if="role == 'admin'"
                    icon
                    class="ms-auto"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </v-card>

            <!--<v-card class="d-flex pa-1 mt-1">
              <div class="mt-auto mb-auto">
                {{ resource.name }}
              </div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="selectNodeWeight(weight)"
                    v-if="role == 'admin'"
                    icon
                    class="ms-auto"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </v-card>-->
          </div>
          <v-btn class="mt-2" @click="addNodeResource" v-if="role == 'admin'"
            >Agregar recurso <v-icon class="ms-2">mdi-plus</v-icon></v-btn
          >
        </v-card>
        <!-- api querying -->
        <v-card
          class="ps-4 pe-4 pt-2 pb-2 mt-2"
          style="background: #dec0e0"
          v-if="internalNode.api"
        >
          <div class="d-flex flex-column pt-2 pb-2" style="gap: 12px">
            <h3 class="text-left">Búsqueda en tabla</h3>
            <div class="d-flex">
              <v-text-field
                dense
                hide-details
                :style="
                  role != 'admin' ? 'pointer-events: none !important' : ''
                "
                :readonly="role != 'admin'"
                label="Nombre de tabla"
                v-model="internalNode.api_table"
              ></v-text-field>
              <tooltip class="mt-2" style="transform: scale(0.8)">
                Nombre de tabla de OpenSearch a buscar. <b>Importante:</b> Las
                tablas de datos de OpenSearch son distintos a los recursos
                asociados. Ejemplo de nombre: universities-2024
              </tooltip>
            </div>
            <div class="d-flex">
              <v-text-field
                dense
                hide-details
                :style="
                  role != 'admin' ? 'pointer-events: none !important' : ''
                "
                :readonly="role != 'admin'"
                label="Filtros de tabla"
                v-model="internalNode.api_filters"
              ></v-text-field>
              <tooltip class="mt-2" style="transform: scale(0.8)">
                Filtros separados por punto coma, en base a las columnas. Es
                posible incluir variables. Por ejemplo: "university:Universidad
                de Chile;career_name:\var(career_name)"
              </tooltip>
            </div>
            <div class="d-flex">
              <v-checkbox
                dense
                hide-details
                :readonly="role != 'admin'"
                :style="
                  role != 'admin' ? 'pointer-events: none !important' : ''
                "
                label="Búsqueda"
                v-model="internalNode.api_search"
              ></v-checkbox>
              <tooltip class="mt-2" style="transform: scale(0.8)">
                En caso de querer más allá que filtrar, buscar uno o más
                elementos en base a un texto proporcionado por el usuario, esta
                opción se debe marcar.
              </tooltip>
            </div>
            <div class="d-flex">
              <v-checkbox
                dense
                hide-details
                :readonly="role != 'admin'"
                :style="
                  role != 'admin' ? 'pointer-events: none !important' : ''
                "
                label="Búsqueda aproximada"
                v-model="internalNode.api_approximate_search"
              ></v-checkbox>
              <tooltip class="mt-2" style="transform: scale(0.8)">
                En caso de querer utilizar búsqueda aproximada (mediante
                embeddings), esta opción se debe marcar.
              </tooltip>
            </div>
            <div class="d-flex">
              <v-text-field
                dense
                hide-details
                :style="
                  role != 'admin' ? 'pointer-events: none !important' : ''
                "
                :readonly="role != 'admin'"
                label="Columna de búsqueda"
                v-model="internalNode.api_search_column"
              ></v-text-field>
              <tooltip class="mt-2" style="transform: scale(0.8)">
                En caso de querer buscar, se debe marcar esta columna.
              </tooltip>
            </div>
            <div class="d-flex">
              <v-text-field
                dense
                hide-details
                :style="
                  role != 'admin' ? 'pointer-events: none !important' : ''
                "
                :readonly="role != 'admin'"
                label="Variable de historial"
                v-model="internalNode.api_variable_set"
              ></v-text-field>
              <tooltip class="mt-2" style="transform: scale(0.8)">
                Nombre a asignar a la variable que mantendrá internamente
                registro de lo que se está buscando. Por lo general, se debe
                utilizar "items_checked". Si el ítem a buscar / filtrar es
                universidades, por ejemplo, se debiese colocar
                "universities_checked".
              </tooltip>
            </div>
            <div class="d-flex">
              <v-text-field
                dense
                hide-details
                :style="
                  role != 'admin' ? 'pointer-events: none !important' : ''
                "
                :readonly="role != 'admin'"
                label="Columna a mostrar (texto)"
                v-model="internalNode.api_shown_variable"
              ></v-text-field>
              <tooltip class="mt-2" style="transform: scale(0.8)">
                Texto principal a mostrar.
              </tooltip>
            </div>
            <!--<div class="d-flex">
              <v-text-field
                dense
                hide-details
                :style="
                  role != 'admin' ? 'pointer-events: none !important' : ''
                "
                :readonly="role != 'admin'"
                label="Columna a mostrar (descripción)"
                v-model="internalNode.api_shown_description_variable"
              ></v-text-field>
              <tooltip class="mt-2" style="transform: scale(0.8)">
                Dejar vacío en caso de no necesitar descripción.
              </tooltip>
            </div>-->
            <div class="d-flex">
              <v-text-field
                dense
                hide-details
                :style="
                  role != 'admin' ? 'pointer-events: none !important' : ''
                "
                :readonly="role != 'admin'"
                label="Identificador de ítem"
                v-model="internalNode.api_variable_set_id"
              ></v-text-field>
              <tooltip class="mt-2" style="transform: scale(0.8)">
                De la tabla, el identificador que nos interesa guardar una vez
                que el usuario seleccione una alternativa. Este identificador
                puede ser utilizado después para obtener información. En general
                debe ser "id".
              </tooltip>
            </div>
            <div class="d-flex">
              <v-text-field
                dense
                hide-details
                :style="
                  role != 'admin' ? 'pointer-events: none !important' : ''
                "
                :readonly="role != 'admin'"
                label="Variable a setear"
                v-model="internalNode.api_variable_set_column"
              ></v-text-field>
              <tooltip class="mt-2" style="transform: scale(0.8)">
                En caso de querer guardar la selección del usuario, variable con
                la cual se asignará. Por ejemplo, si se están buscando
                universidades, este campo debería ser "selected_university".
              </tooltip>
            </div>
            <div class="d-flex">
              <v-text-field
                dense
                hide-details
                :style="
                  role != 'admin' ? 'pointer-events: none !important' : ''
                "
                :readonly="role != 'admin'"
                label="Característica a setear"
                v-model="internalNode.api_feature_set"
              ></v-text-field>
              <tooltip class="mt-2" style="transform: scale(0.8)">
                En caso de ser necesario, se puede setear alguna característica
                <b>ya existente</b> con el valor de "api_variable_set_id". Esto
                para poder afectar flujos.
              </tooltip>
            </div>
          </div>
        </v-card>
        <!-- endpoints -->
        <!--<v-card class="ps-4 pe-4 pt-2 pb-2 mt-2" style="background: lightpink">
          <div class="d-flex pt-2 pb-2">
            <h3 class="text-left">Llamadas asociadas este nodo:</h3>
            <tooltip>
              Esta sección permite asociar variables y características con
              llamadas a APIs, de tal manera de mostrar, en base a dichas
              variables, un listado de respuestas de dichas APIs.
            </tooltip>
          </div>

          <div v-for="(resource, index) in internalNode.resources" :key="index">
            <v-card class="d-flex pa-1 mt-1">
              <div class="mt-auto mb-auto">
                {{ resource.name }}
              </div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="selectNodeWeight(weight)"
                    v-if="role == 'admin'"
                    icon
                    class="ms-auto"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </v-card>
          </div>
          <v-btn class="mt-2" @click="sendAddNodeWeight" v-if="role == 'admin'"
            >Agregar ponderador <v-icon class="ms-2">mdi-plus</v-icon></v-btn
          >
        </v-card>-->
      </div>

      <v-btn
        class="mt-3"
        color="#FFCCBC"
        @click="$emit('testBotAtNode', internalNode)"
        >Testear bot desde aquí</v-btn
      >
    </generic-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import NodeWeight from '@/components/NodeWeight.vue';
import AnswerValidation from '@/components/AnswerValidation.vue';
import NodeVariable from '@/components/NodeVariable.vue';
import NodeResource from '@/components/NodeResource.vue';
import Answer from '@/components/Answer.vue';
import CommentCreator from '@/components/CommentCreator.vue';

export default {
  name: 'Node',
  components: {
    NodeWeight,
    Answer,
    CommentCreator,
    NodeVariable,
    NodeResource,
    AnswerValidation,
  },
  props: {
    node: {
      required: true,
      type: Object,
    },
  },
  mounted() {
    this.internalNode = { ...this.node };
    if (this.chatbot.resources == undefined) {
      this.logout();
    }
    if (this.needsApproval) {
      this.checkApproval();
      this.timer = setInterval(() => {
        this.checkApproval();
      }, 30000);
    }
    this.$nextTick(() => {
      if (this.$refs.form) {
        this.$refs.form.validate();
      }
    });
  },
  watch: {
    'internalNode.type': function (newValue) {
      if (newValue == 'text') {
        this.warning =
          'La máxima cantidad de caracteres que permite twilio para enviar mensajes de texto vía whatsapp es de 1600, si te pasas de esto puede que no se mande todo tu texto.';
      }
      if (newValue == 'quick-reply') {
        this.warning =
          'La máxima cantidad de botones que permite twilio en quick-reply son 3, y cada botón tiene un máximo de 25 caracteres.';
      }
      if (newValue == 'list-picker') {
        this.warning =
          'La máxima cantidad de items que permite twilio en list-picker son 10, cada uno con un título de 24 caracteres como máximo y una descripción de 72 caracteres.';
      }
      if (newValue == 'media') {
        this.warning =
          'Procura que tu archivo sea de tipo jpg, jpeg o png para imágenes; o mp4, mov o 3gp para videos.';
      }
    },
  },
  data() {
    return {
      timer: null,
      showUnsavedChangesDialog: false,
      deleteDialog: false,
      showNode: true,
      internalNode: null,
      nodeWeight: null,
      editNodeWeight: false,
      answerValidation: null,
      editAnswerValidation: null,
      answer: null,
      editAnswer: false,
      nodeVariable: null,
      editNodeVariable: false,
      nodeResource: null,
      editNodeResource: false,
      addComment: false,
      //warning: '',
    };
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    getResourceName(resourceId) {
      let resource = this.chatbot.resources.find((r) => r.id == resourceId);
      if (resource) {
        return resource.name;
      }
      return '';
    },
    addNodeResource() {
      this.addResource({
        node: this.internalNode.id,
        resource: null,
      });
    },
    isDeepEqual(obj1, obj2) {
      // If both are null or undefined, return true
      if (obj1 === obj2) {
        return true;
      }

      // If either is null or undefined (but not both, as checked above), return false
      if (obj1 == null || obj2 == null) {
        return false;
      }

      // If both are not objects (e.g., are primitive types), return strict equality result
      if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return obj1 === obj2;
      }

      // Get keys of both objects
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      // If number of keys is different, objects are not equal
      if (keys1.length !== keys2.length) {
        return false;
      }

      // Check keys in obj1 are in obj2 and recursively check their values for deep equality
      for (const key of keys1) {
        if (!keys2.includes(key) || !this.isDeepEqual(obj1[key], obj2[key])) {
          if (
            ![
              'label',
              'allText',
              'variables',
              'status',
              'pos_x',
              'pos_y',
            ].includes(key)
          ) {
            return false;
          }
        }
      }

      // If all tests passed, objects are deeply equal
      return true;
    },
    ...mapActions({
      logout: 'utils/logout',
      saveNode: 'chatbot/saveNode',
      deleteNode: 'chatbot/deleteNode',
      addNodeVariable: 'chatbot/addNodeVariable',
      addAnswer: 'chatbot/addAnswer',
      addResource: 'chatbot/addNodeResource',
      deleteResource: 'chatbot/deleteNodeResource',
      saveResource: 'chatbot/saveNodeResource',
      addNodeWeight: 'chatbot/addNodeWeight',
      addAnswerValidation: 'chatbot/addAnswerValidation',
      setToastNotification: 'utils/setToastNotification',
      request: 'utils/request',
    }),
    checkApproval() {
      this.request({
        type: 'get',
        endpoint: `get_node_status/${this.internalNode.id}`,
      })
        .then((response) => {
          let { status } = response.data.data;
          this.saveNode({
            node: {
              id: this.internalNode.id,
              status: status,
              name: this.internalNode.name,
            },
            callback: false,
          }).then(() => {
            this.internalNode.status = status;
            if (status == 'approved') {
              if (this.timer) {
                clearInterval(this.timer);
              }
            }
          });
        })
        .catch(() => {
          if (this.timer) {
            clearInterval(this.timer);
          }
        });
    },
    approve() {
      this.request({
        errorMessage: 'Error soliciting approval',
        successMessage: 'Approval solicited successfully',
        type: 'post',
        endpoint: `approve_node/`,
        body: {
          node: this.internalNode.id,
          variables: {},
        },
      }).then(() => {
        this.request({
          type: 'get',
          endpoint: `node/${this.internalNode.id}`,
        }).then((response) => {
          this.saveNode({ node: response.data, callback: false }).then(() => {
            this.internalNode = { ...this.node };
            if (!this.timer) {
              this.checkApproval();
              this.timer = setInterval(() => {
                this.checkApproval();
              }, 30000);
            }
          });
        });
        // let { content_sid, status } = response.data.data;
        // this.internalNode.status = status;
        // this.internalNode.content_sid = content_sid;
      });
    },
    checkDisabledAddAnswer() {
      if (this.internalNode.type == 'text') {
        return "El nodo no puede tener respuestas ya que no es de tipo 'quick-reply' o 'list-picker'.";
      } else if (this.internalNode.answers.length == 10) {
        return 'El nodo no puede tener sobre 10 respuestas.';
      } else if (
        this.internalNode.answers.length == 3 &&
        this.internalNode.type == 'quick-reply'
      ) {
        return "El nodo no puede tener sobre 3 respuestas ya que es de tipo 'quick-reply'. Cambiar tipo a 'list-picker' para poder agregar más respuestas.";
      }
      return false;
    },
    shortName(name) {
      if (name && name.length < 50) {
        return true;
      }
      return false;
    },
    validateName(name) {
      const pattern = /^[a-zA-Z0-9_]+$/;
      return pattern.test(name);
    },
    getWarning(type) {
      if (type == 'text') {
        return 'La máxima cantidad de caracteres que permite twilio para enviar mensajes de texto vía whatsapp es de 1600, si te pasas de esto puede que no se mande todo tu texto.';
      }
      if (type == 'quick-reply') {
        return 'La máxima cantidad de botones que permite twilio en quick-reply son 3, y cada botón tiene un máximo de 25 caracteres.';
      }
      if (type == 'list-picker') {
        return 'La máxima cantidad de items que permite twilio en list-picker son 10, cada uno con un título de 24 caracteres como máximo y una descripción de 72 caracteres.';
      }
      if (type == 'media') {
        return 'Procura que tu archivo sea de tipo jpg, jpeg o png para imágenes; o mp4, mov o 3gp para videos.';
      }
    },
    selectNodeResource(nodeResource) {
      this.nodeResource = null;
      this.$nextTick(() => {
        this.nodeResource = nodeResource;
        if (this.nodeResource) {
          this.editNodeResource = true;
        }
      });
    },
    selectNodeVariable(nodeVariable) {
      this.nodeVariable = null;
      this.$nextTick(() => {
        this.nodeVariable = nodeVariable;
        if (this.nodeVariable) {
          this.editNodeVariable = true;
        }
      });
    },
    selectAnswer(answer) {
      this.answer = null;
      this.$nextTick(() => {
        this.answer = answer;
        if (this.answer) {
          this.editAnswer = true;
        }
      });
    },
    selectNodeWeight(nodeWeight) {
      this.nodeWeight = null;
      this.$nextTick(() => {
        this.nodeWeight = nodeWeight;
        if (nodeWeight) {
          this.editNodeWeight = true;
        }
      });
    },
    selectAnswerValidation(answerValidation) {
      this.answerValidation = null;
      this.$nextTick(() => {
        this.answerValidation = answerValidation;
        if (answerValidation) {
          this.editAnswerValidation = true;
        }
      });
    },
    sendAddNodeWeight() {
      if (this.node.weights.filter((w) => w.feature == 'default').length > 0) {
        this.setToastNotification({
          toastState: false,
          toastMessage:
            'Ya existe peso de salida con característica por defecto. Favor modificarla antes de agregar otra.',
        });
      } else {
        this.addNodeWeight({
          node: this.internalNode.id,
          feature: 'default',
          weight: 0,
        });
      }
    },
    sendAddAnswerValidation() {
      this.addAnswerValidation({
        node: this.internalNode.id,
        category: 'eq',
        value: '0',
      });
    },
    sendSaveNode() {
      // get all key-values where value is not array
      let nodeProperties = Object.entries(this.internalNode).reduce(
        (acc, [key, value]) => {
          if (!Array.isArray(value) && typeof value !== 'object') {
            acc[key] = value;
          }
          return acc;
        },
        {},
      );
      // remove unused properties from node model
      // that are used for visualization
      ['label', 'color'].forEach((k) => {
        if (k in nodeProperties) {
          delete nodeProperties[k];
        }
      });
      let node = this.node;
      this.node = null;
      this.saveNode({ node: nodeProperties, callback: true }).then(() => {
        this.node = node;
      });
    },
    removeNode() {
      this.deleteNode({ node: this.internalNode.id }).then(() => {
        this.$emit('setNode', null);
      });
    },
  },
  computed: {
    hasChanges() {
      return !this.isDeepEqual(this.node, this.internalNode);
    },
    getApprovalColor() {
      return {
        Requerido: 'red lighten-1',
        Aprobado: 'green lighten-1',
        Pendiente: 'yellow lighten-1',
        Rechazado: 'red lighten-1',
        required: 'red lighten-1',
        approved: 'green lighten-1',
        pending: 'yellow lighten-1',
        received: 'yellow lighten-1',
        rejected: 'red lighten-1',
      }[this.computedStatus];
    },
    computedStatus() {
      if (this.internalNode) {
        if (
          this.internalNode.start ||
          this.internalNode.type == 'card' ||
          this.internalNode.type == 'link'
        ) {
          if (this.internalNode.status == '') {
            return 'Requerido';
          } else if (this.internalNode.status == 'approved') {
            return 'Aprobado';
          } else if (this.internalNode.status == 'rejected') {
            return 'Rechazado';
          } else if (
            this.internalNode.status == 'pending' ||
            this.internalNode.status == 'received'
          ) {
            return 'Pendiente';
          }
        }
      }
      return 'Aprobado';
    },
    needsApproval() {
      if (
        this.node.start ||
        this.node.type == 'link' ||
        this.node.type == 'card'
      ) {
        return true;
      }
      return false;
    },
    validName() {
      if (
        this.validateName(this.internalNode.name) &&
        this.shortName(this.internalNode.name)
      ) {
        return true;
      }
      return false;
    },
    sortedValidations() {
      if (this.internalNode) {
        let validations = [...this.internalNode.validations];
        validations.sort((a, b) => a.number - b.number);
        return validations;
      }
      return [];
    },
    sortedAnswers() {
      if (this.internalNode) {
        let answers = [...this.internalNode.answers];
        answers.sort((a, b) => a.number - b.number);
        return answers;
      }
      return [];
    },
    ...mapGetters({
      role: 'chatbot/role',
      chatbot: 'chatbot/chatbot',
    }),
  },
};
</script>
